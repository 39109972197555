import { component } from "@uesio/ui";
import accordion from "../src/components/accordion/accordion";
import avatar from "../src/components/avatar/avatar";
import box from "../src/components/box/box";
import button from "../src/components/button/button";
import code from "../src/components/code/code";
import deck from "../src/components/deck/deck";
import dialog from "../src/components/dialog/dialog";
import dialogplain from "../src/components/dialogplain/dialogplain";
import dynamicpanel from "../src/components/dynamicpanel/dynamicpanel";
import emptystate from "../src/components/emptystate/emptystate";
import field from "../src/components/field/field";
import fileattachment from "../src/components/fileattachment/fileattachment";
import filter from "../src/components/filter/filter";
import grid from "../src/components/grid/grid";
import griditem from "../src/components/griditem/griditem";
import group from "../src/components/group/group";
import iconlabel from "../src/components/iconlabel/iconlabel";
import image from "../src/components/image/image";
import item from "../src/components/item/item";
import link from "../src/components/link/link";
import list from "../src/components/list/list";
import markdown from "../src/components/markdown/markdown";
import markdownnavigation from "../src/components/markdownnavigation/markdownnavigation";
import menu from "../src/components/menu/menu";
import menubutton from "../src/components/menubutton/menubutton";
import metric from "../src/components/metric/metric";
import scrollpanel from "../src/components/scrollpanel/scrollpanel";
import searchbox from "../src/components/searchbox/searchbox";
import sidepanel from "../src/components/sidepanel/sidepanel";
import sidepanelplain from "../src/components/sidepanelplain/sidepanelplain";
import tablabels from "../src/components/tablabels/tablabels";
import table from "../src/components/table/table";
import tabs from "../src/components/tabs/tabs";
import text from "../src/components/text/text";
import tile from "../src/components/tile/tile";
import titlebar from "../src/components/titlebar/titlebar";
import toolbar from "../src/components/toolbar/toolbar";
import video from "../src/components/video/video";
import viewheader from "../src/components/viewheader/viewheader";
import viewlayout from "../src/components/viewlayout/viewlayout";
import avatar_utility from "../src/utilities/avatar/avatar";
import button_utility from "../src/utilities/button/button";
import checkboxfield_utility from "../src/utilities/field/checkbox";
import checkboxfilter_utility from "../src/utilities/checkboxfilter/checkboxfilter";
import codefield_utility from "../src/utilities/codefield/codefield";
import customselect_utility from "../src/utilities/customselect/customselect";
import datefield_utility from "../src/utilities/field/date";
import datefilter_utility from "../src/utilities/datefilter/datefilter";
import dialog_utility from "../src/utilities/dialog/dialog";
import dialogplain_utility from "../src/utilities/dialogplain/dialogplain";
import dynamicform_utility from "../src/utilities/dynamicform/dynamicform";
import dynamictable_utility from "../src/utilities/dynamictable/dynamictable";
import expandpanel_utility from "../src/utilities/expandpanel/expandpanel";
import field_utility from "../src/utilities/field/field";
import fieldlabel_utility from "../src/utilities/fieldlabel/fieldlabel";
import fieldset_utility from "../src/utilities/fieldset/fieldset";
import fieldwrapper_utility from "../src/utilities/fieldwrapper/fieldwrapper";
import file_utility from "../src/utilities/file/file";
import fileimage_utility from "../src/utilities/fileimage/fileimage";
import filepreview_utility from "../src/utilities/filepreview/filepreview";
import filetext_utility from "../src/utilities/filetext/filetext";
import filevideo_utility from "../src/utilities/filevideo/filevideo";
import grid_utility from "../src/utilities/grid/grid";
import group_utility from "../src/utilities/group/group";
import icon_utility from "../src/utilities/icon/icon";
import iconbutton_utility from "../src/utilities/iconbutton/iconbutton";
import iconlabel_utility from "../src/utilities/iconlabel/iconlabel";
import image_utility from "../src/utilities/image/image";
import link_utility from "../src/utilities/link/link";
import listfield_utility from "../src/utilities/field/list";
import listfielddeck_utility from "../src/utilities/field/listdeck";
import listmenu_utility from "../src/utilities/listmenu/listmenu";
import mapfield_utility from "../src/utilities/mapfield/mapfield";
import markdownfield_utility from "../src/utilities/markdownfield/markdownfield";
import menubutton_utility from "../src/utilities/menubutton/menubutton";
import metadatafield_utility from "../src/utilities/field/metadata";
import metric_utility from "../src/utilities/metric/metric";
import monthfilter_utility from "../src/utilities/monthfilter/monthfilter";
import multicheckfield_utility from "../src/utilities/field/multicheck";
import multiselectfield_utility from "../src/utilities/field/multiselect";
import multiselectfilter_utility from "../src/utilities/multiselectfilter/multiselectfilter";
import namespacelabel_utility from "../src/utilities/namespacelabel/namespacelabel";
import numberfield_utility from "../src/utilities/field/number";
import numberfilter_utility from "../src/utilities/numberfilter/numberfilter";
import paginator_utility from "../src/utilities/paginator/paginator";
import pillbox_utility from "../src/utilities/pillbox/pillbox";
import popper_utility from "../src/utilities/popper/popper";
import radiobuttonsfield_utility from "../src/utilities/field/radiobuttons";
import referencefield_utility from "../src/utilities/field/reference";
import referencefilter_utility from "../src/utilities/referencefilter/referencefilter";
import referencegroupfield_utility from "../src/utilities/field/referencegroup";
import scrollpanel_utility from "../src/utilities/scrollpanel/scrollpanel";
import selectfield_utility from "../src/utilities/field/select";
import selectfilter_utility from "../src/utilities/selectfilter/selectfilter";
import sidepanel_utility from "../src/utilities/sidepanel/sidepanel";
import structfield_utility from "../src/utilities/structfield/structfield";
import tablabels_utility from "../src/utilities/tablabels/tablabels";
import table_utility from "../src/utilities/table/table";
import text_utility from "../src/utilities/text/text";
import textareafield_utility from "../src/utilities/field/textarea";
import textfield_utility from "../src/utilities/field/text";
import textfilter_utility from "../src/utilities/textfilter/textfilter";
import tile_utility from "../src/utilities/tile/tile";
import timestampfield_utility from "../src/utilities/field/timestamp";
import timestampfilter_utility from "../src/utilities/timestampfilter/timestampfilter";
import titlebar_utility from "../src/utilities/titlebar/titlebar";
import togglefield_utility from "../src/utilities/field/toggle";
import tooltip_utility from "../src/utilities/tooltip/tooltip";
import uploadarea_utility from "../src/utilities/uploadarea/uploadarea";
import userfield_utility from "../src/utilities/field/user";
import userfile_utility from "../src/utilities/userfile/userfile";
import viewheader_utility from "../src/utilities/viewheader/viewheader";
import weekfilter_utility from "../src/utilities/weekfilter/weekfilter";
component.registry.register("uesio/io.accordion",accordion);
component.registry.register("uesio/io.avatar",avatar);
component.registry.register("uesio/io.box",box);
component.registry.register("uesio/io.button",button);
component.registry.register("uesio/io.code",code);
component.registry.register("uesio/io.deck",deck);
component.registry.register("uesio/io.dialog",dialog);
component.registry.register("uesio/io.dialogplain",dialogplain);
component.registry.register("uesio/io.dynamicpanel",dynamicpanel);
component.registry.register("uesio/io.emptystate",emptystate);
component.registry.register("uesio/io.field",field);
component.registry.register("uesio/io.fileattachment",fileattachment);
component.registry.register("uesio/io.filter",filter);
component.registry.register("uesio/io.grid",grid);
component.registry.register("uesio/io.griditem",griditem);
component.registry.register("uesio/io.group",group);
component.registry.register("uesio/io.iconlabel",iconlabel);
component.registry.register("uesio/io.image",image);
component.registry.register("uesio/io.item",item);
component.registry.register("uesio/io.link",link);
component.registry.register("uesio/io.list",list);
component.registry.register("uesio/io.markdown",markdown);
component.registry.register("uesio/io.markdownnavigation",markdownnavigation);
component.registry.register("uesio/io.menu",menu);
component.registry.register("uesio/io.menubutton",menubutton);
component.registry.register("uesio/io.metric",metric);
component.registry.register("uesio/io.scrollpanel",scrollpanel);
component.registry.register("uesio/io.searchbox",searchbox);
component.registry.register("uesio/io.sidepanel",sidepanel);
component.registry.register("uesio/io.sidepanelplain",sidepanelplain);
component.registry.register("uesio/io.tablabels",tablabels);
component.registry.register("uesio/io.table",table);
component.registry.register("uesio/io.tabs",tabs);
component.registry.register("uesio/io.text",text);
component.registry.register("uesio/io.tile",tile);
component.registry.register("uesio/io.titlebar",titlebar);
component.registry.register("uesio/io.toolbar",toolbar);
component.registry.register("uesio/io.video",video);
component.registry.register("uesio/io.viewheader",viewheader);
component.registry.register("uesio/io.viewlayout",viewlayout);
component.registry.registerUtilityComponent("uesio/io.avatar",avatar_utility)
component.registry.registerUtilityComponent("uesio/io.button",button_utility)
component.registry.registerUtilityComponent("uesio/io.checkboxfield",checkboxfield_utility)
component.registry.registerUtilityComponent("uesio/io.checkboxfilter",checkboxfilter_utility)
component.registry.registerUtilityComponent("uesio/io.codefield",codefield_utility)
component.registry.registerUtilityComponent("uesio/io.customselect",customselect_utility)
component.registry.registerUtilityComponent("uesio/io.datefield",datefield_utility)
component.registry.registerUtilityComponent("uesio/io.datefilter",datefilter_utility)
component.registry.registerUtilityComponent("uesio/io.dialog",dialog_utility)
component.registry.registerUtilityComponent("uesio/io.dialogplain",dialogplain_utility)
component.registry.registerUtilityComponent("uesio/io.dynamicform",dynamicform_utility)
component.registry.registerUtilityComponent("uesio/io.dynamictable",dynamictable_utility)
component.registry.registerUtilityComponent("uesio/io.expandpanel",expandpanel_utility)
component.registry.registerUtilityComponent("uesio/io.field",field_utility)
component.registry.registerUtilityComponent("uesio/io.fieldlabel",fieldlabel_utility)
component.registry.registerUtilityComponent("uesio/io.fieldset",fieldset_utility)
component.registry.registerUtilityComponent("uesio/io.fieldwrapper",fieldwrapper_utility)
component.registry.registerUtilityComponent("uesio/io.file",file_utility)
component.registry.registerUtilityComponent("uesio/io.fileimage",fileimage_utility)
component.registry.registerUtilityComponent("uesio/io.filepreview",filepreview_utility)
component.registry.registerUtilityComponent("uesio/io.filetext",filetext_utility)
component.registry.registerUtilityComponent("uesio/io.filevideo",filevideo_utility)
component.registry.registerUtilityComponent("uesio/io.grid",grid_utility)
component.registry.registerUtilityComponent("uesio/io.group",group_utility)
component.registry.registerUtilityComponent("uesio/io.icon",icon_utility)
component.registry.registerUtilityComponent("uesio/io.iconbutton",iconbutton_utility)
component.registry.registerUtilityComponent("uesio/io.iconlabel",iconlabel_utility)
component.registry.registerUtilityComponent("uesio/io.image",image_utility)
component.registry.registerUtilityComponent("uesio/io.link",link_utility)
component.registry.registerUtilityComponent("uesio/io.listfield",listfield_utility)
component.registry.registerUtilityComponent("uesio/io.listfielddeck",listfielddeck_utility)
component.registry.registerUtilityComponent("uesio/io.listmenu",listmenu_utility)
component.registry.registerUtilityComponent("uesio/io.mapfield",mapfield_utility)
component.registry.registerUtilityComponent("uesio/io.markdownfield",markdownfield_utility)
component.registry.registerUtilityComponent("uesio/io.menubutton",menubutton_utility)
component.registry.registerUtilityComponent("uesio/io.metadatafield",metadatafield_utility)
component.registry.registerUtilityComponent("uesio/io.metric",metric_utility)
component.registry.registerUtilityComponent("uesio/io.monthfilter",monthfilter_utility)
component.registry.registerUtilityComponent("uesio/io.multicheckfield",multicheckfield_utility)
component.registry.registerUtilityComponent("uesio/io.multiselectfield",multiselectfield_utility)
component.registry.registerUtilityComponent("uesio/io.multiselectfilter",multiselectfilter_utility)
component.registry.registerUtilityComponent("uesio/io.namespacelabel",namespacelabel_utility)
component.registry.registerUtilityComponent("uesio/io.numberfield",numberfield_utility)
component.registry.registerUtilityComponent("uesio/io.numberfilter",numberfilter_utility)
component.registry.registerUtilityComponent("uesio/io.paginator",paginator_utility)
component.registry.registerUtilityComponent("uesio/io.pillbox",pillbox_utility)
component.registry.registerUtilityComponent("uesio/io.popper",popper_utility)
component.registry.registerUtilityComponent("uesio/io.radiobuttonsfield",radiobuttonsfield_utility)
component.registry.registerUtilityComponent("uesio/io.referencefield",referencefield_utility)
component.registry.registerUtilityComponent("uesio/io.referencefilter",referencefilter_utility)
component.registry.registerUtilityComponent("uesio/io.referencegroupfield",referencegroupfield_utility)
component.registry.registerUtilityComponent("uesio/io.scrollpanel",scrollpanel_utility)
component.registry.registerUtilityComponent("uesio/io.selectfield",selectfield_utility)
component.registry.registerUtilityComponent("uesio/io.selectfilter",selectfilter_utility)
component.registry.registerUtilityComponent("uesio/io.sidepanel",sidepanel_utility)
component.registry.registerUtilityComponent("uesio/io.structfield",structfield_utility)
component.registry.registerUtilityComponent("uesio/io.tablabels",tablabels_utility)
component.registry.registerUtilityComponent("uesio/io.table",table_utility)
component.registry.registerUtilityComponent("uesio/io.text",text_utility)
component.registry.registerUtilityComponent("uesio/io.textareafield",textareafield_utility)
component.registry.registerUtilityComponent("uesio/io.textfield",textfield_utility)
component.registry.registerUtilityComponent("uesio/io.textfilter",textfilter_utility)
component.registry.registerUtilityComponent("uesio/io.tile",tile_utility)
component.registry.registerUtilityComponent("uesio/io.timestampfield",timestampfield_utility)
component.registry.registerUtilityComponent("uesio/io.timestampfilter",timestampfilter_utility)
component.registry.registerUtilityComponent("uesio/io.titlebar",titlebar_utility)
component.registry.registerUtilityComponent("uesio/io.togglefield",togglefield_utility)
component.registry.registerUtilityComponent("uesio/io.tooltip",tooltip_utility)
component.registry.registerUtilityComponent("uesio/io.uploadarea",uploadarea_utility)
component.registry.registerUtilityComponent("uesio/io.userfield",userfield_utility)
component.registry.registerUtilityComponent("uesio/io.userfile",userfile_utility)
component.registry.registerUtilityComponent("uesio/io.viewheader",viewheader_utility)
component.registry.registerUtilityComponent("uesio/io.weekfilter",weekfilter_utility)