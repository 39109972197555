import { definition, api, wire, collection } from "@uesio/ui"
import ReferenceField, { ReferenceFieldOptions } from "../field/reference"

interface ReferenceFilterProps {
  path: string
  wire: wire.Wire
  fieldMetadata: collection.Field
  condition: wire.ValueConditionState
  options?: ReferenceFieldOptions
}

const ReferenceFilter: definition.UtilityComponent<ReferenceFilterProps> = (
  props,
) => {
  const { context, condition, fieldMetadata, options, path, wire, variant } =
    props
  const wireId = wire.getId()
  return (
    <ReferenceField
      path={path}
      mode={"EDIT"}
      fieldId={fieldMetadata.getId()}
      fieldMetadata={fieldMetadata}
      placeholder={"Any " + fieldMetadata.getLabel()}
      context={context}
      variant={variant}
      options={options}
      setValue={(value: wire.PlainWireRecord) => {
        api.signal.runMany(
          [
            {
              signal: "wire/SET_CONDITION",
              wire: wireId,
              condition: {
                ...condition,
                value: value ? value[collection.ID_FIELD] : null,
                inactive: value === null,
              },
            },
            {
              signal: "wire/LOAD",
              wires: [wireId],
            },
          ],
          context,
        )
      }}
    />
  )
}

export default ReferenceFilter
